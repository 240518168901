.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body:not(.mobile-version) #root {
    min-height: 100vh;
}
body #root {
    position: relative;
    z-index: 1;
}
body:not(.web-template) #root {
    max-width: 500px;
    margin: 0 auto;
    padding-bottom: 50px;
}

@media only screen and (min-width: 1024px) {
    .mobile-version .modifyItemPopup {
        max-width: 390px !important;
    }
    .mobile-version .btnWrapper .cartBtn {
        font-size: 14px !important;
    }
    .mobile-version .btnWrapper {
        max-width: 390px !important;
    }
    .mobile-version {
        max-width: 390px; /* iPhone12 screen width */
        margin: 20px auto;
        overflow: hidden;
    }
    .mobile-version #root::-webkit-scrollbar {
        background-color: transparent;
        width: 0;
    }
    .mobile-version #root::-webkit-scrollbar-thumb {
        border-radius: 4px;
    }
    .mobile-version #root .no-favorites {
        padding: 0 20px;
    }
    .mobile-version #root .fixed-position {
        top: 40px;
    }
    .mobile-version #root {
        max-height: 800px;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 90px);
        box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px inset;
        border-radius: 20px;
    }
    .mobile-version .body-wrapper {
        border-radius: 20px;
        position: relative;
    }
    .mobile-version #body-background {
        border-radius: 20px;
        min-height: 100% !important;
    }
}
