.navbar-container {
    overflow-x: scroll;
    display: flex;
    padding: 0 20px 0 20px;
    /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.navbar-container::-webkit-scrollbar {
    display: none;
}
